import { apiInstance } from "src/configs/axios.config";

import { ProtocolTypeResponse, EstimateProtocolTypeResponse, IProtocolDetailDataResponse } from 'src/types/protocol.type';

const PROTOCOL_MODULE = "protocols";

export const getProtocols = (configs = {}) => apiInstance.get<{ id: string; name: string; }[]>(`${PROTOCOL_MODULE}`, configs);
export const getProtocolsCategory = (configs = {}) => apiInstance.get<string[]>(`${PROTOCOL_MODULE}/categories`, configs);
export const getProtocolsChains = (configs = {}) => apiInstance.get<string[]>(`${PROTOCOL_MODULE}/chains`, configs);
export const searchProtocols = (configs = {}) => apiInstance.get<ProtocolTypeResponse>(`${PROTOCOL_MODULE}/search`, configs);
export const getEstimateCategory = (configs = {}) => apiInstance.get<string[]>(`${PROTOCOL_MODULE}/estimate-category`, configs);
export const getEstimateProtocol = (configs = {}) => apiInstance.get<EstimateProtocolTypeResponse[]>(`${PROTOCOL_MODULE}/estimate-protocol`, configs);
export const getProtocolDetail = (id = "", configs = {}) => apiInstance.get<IProtocolDetailDataResponse>(`${PROTOCOL_MODULE}/protocol/${id}`, configs);
export const searchSimilarProtocols = (configs = {}) => apiInstance.get<ProtocolTypeResponse>(`${PROTOCOL_MODULE}/search-similar`, configs);