import React, { useState, createContext } from "react";
import { SortingState, OnChangeFn, ColumnSort } from '@tanstack/react-table';
import { IProtocolList } from "src/types/protocol.type";

interface ISelect {
  label: string;
  value: string;
}

interface ICompare {
  id: string;
  category: string;
}

export interface IProtocolContext {
  protocol: ISelect[];
  chain: string[];
  category: string[];
  sorting: SortingState;
  sortBy: string;
  pageIndex: number;
  compareProtocol: ICompare[];
  showSimilar: boolean;
  similarPageIndex: number;
  similarSortby: string;
  similarSorting: SortingState;
  updateProtocol: (newProtocol: ISelect[]) => void;
  updateChain: (newChain: string[]) => void;
  updateCategory: (newCategory: string[]) => void;
  updateSorting: OnChangeFn<SortingState>;
  updatePageIndex: (pageIndex: number) => void;
  updateCompareProtocol: (protocol: IProtocolList) => void;
  clearCompareProtocol: () => void;
  updateShowSimilar: (value: boolean) => void;
  updateSimilarSorting: OnChangeFn<SortingState>;
  updateSimilarPageIndex: (pageIndex: number) => void;
};

const defaultValue: IProtocolContext = {
  protocol: [],
  chain: [],
  category: [],
  sorting: [
    { id: "tvl", desc: true }
  ],
  sortBy: "tvl:desc,name:asc",
  pageIndex: 0,
  compareProtocol: [],
  showSimilar: false,
  similarPageIndex: 0,
  similarSortby: "tvl:desc,name:asc",
  similarSorting: [
    { id: "tvl", desc: true }
  ],
  updateProtocol: () => { },
  updateChain: () => { },
  updateCategory: () => { },
  updateSorting: () => { },
  updatePageIndex: () => { },
  updateCompareProtocol: () => { },
  clearCompareProtocol: () => { },
  updateShowSimilar: () => { },
  updateSimilarSorting: () => { },
  updateSimilarPageIndex: () => { },
};

export const ProtocolContext = createContext<IProtocolContext>(defaultValue);

export const ProtocolProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [protocol, setProtocol] = useState(defaultValue.protocol);
  const [chain, setChain] = useState(defaultValue.chain);
  const [category, setCategory] = useState(defaultValue.category);
  const [sorting, setSorting] = useState(defaultValue.sorting);
  const [sortBy, setSortBy] = useState(defaultValue.sortBy);
  const [pageIndex, setPageIndex] = useState(defaultValue.pageIndex);
  const [compareProtocol, setCompareProtocol] = useState(defaultValue.compareProtocol);
  const [showSimilar, setShowSimilar] = useState(defaultValue.showSimilar);
  const [similarPageIndex, setSimilarPageIndex] = useState(defaultValue.similarPageIndex);
  const [similarSortby, setSimilarSortby] = useState(defaultValue.similarSortby);
  const [similarSorting, setSimilarSorting] = useState(defaultValue.similarSorting);

  const updateProtocol = (newProtocol: ISelect[]) => {
    setProtocol(newProtocol);
    setPageIndex(0);
    setSimilarPageIndex(0);
  };
  const updateChain = (newChain: string[]) => {
    setChain(newChain);
    setPageIndex(0);
    setSimilarPageIndex(0);
  };
  const updateCategory = (newCategory: string[]) => {
    setCategory(newCategory);
    setPageIndex(0);
    setSimilarPageIndex(0);
  };
  const updateSortingHelper = (updaterOrValue: any, oldSorting: SortingState) => {
    let tempSort: SortingState;
    if (typeof updaterOrValue === 'function') {
      tempSort = updaterOrValue(oldSorting);
    } else {
      tempSort = updaterOrValue;
    }
    const newValue = tempSort.at(-1);
    let newSort: SortingState = [];
    for (let oldSort of oldSorting) {
      if (newValue && newValue.id === oldSort.id) {
        newSort.push({ id: oldSort.id, desc: newValue.desc });
      } else {
        newSort.push({ ...oldSort });
      }
    }
    return { newValue, newSort };
  };
  const updateSortByHelper = (newValue: ColumnSort | undefined, oldSorting: SortingState) => {
    if (!newValue) {
      return "";
    }
    const filterdSorting = oldSorting.filter(({ id }) => id !== newValue.id);
    let newSortBy = `${newValue.id}:${newValue.desc ? "desc" : "asc"}`;
    for (let sorting of filterdSorting) {
      newSortBy += `,${sorting.id}:${sorting.desc ? "desc" : "asc"}`;
    }
    return newSortBy;
  };
  const updateSorting: OnChangeFn<SortingState> = (updaterOrValue) => {
    const { newValue, newSort } = updateSortingHelper(updaterOrValue, sorting);
    const newSortBy = updateSortByHelper(newValue, sorting);
    setSortBy(newSortBy);
    setSorting(newSort);
  };
  const updatePageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };
  const updateCompareProtocol = (row: IProtocolList) => {
    // remove selected row by id
    let newCompareProtocol = compareProtocol.filter((pool) => pool.id !== row.id);
    // if same length mean new add
    if (newCompareProtocol.length === compareProtocol.length) {
      newCompareProtocol = [...newCompareProtocol, { id: row.id, category: row.category }];
    }
    setCompareProtocol(newCompareProtocol);
  };
  const clearCompareProtocol = () => {
    setCompareProtocol([]);
  };
  const updateShowSimilar = (value: boolean) => {
    setShowSimilar(value);
  };
  const updateSimilarSorting: OnChangeFn<SortingState> = (updaterOrValue) => {
    const { newValue, newSort } = updateSortingHelper(updaterOrValue, similarSorting);
    const newSortBy = updateSortByHelper(newValue, similarSorting);
    setSimilarSortby(newSortBy);
    setSimilarSorting(newSort);
  };
  const updateSimilarPageIndex = (pageIndex: number) => {
    setSimilarPageIndex(pageIndex);
  };

  return (
    <ProtocolContext.Provider value={{
      protocol,
      chain,
      category,
      sortBy,
      sorting,
      pageIndex,
      compareProtocol,
      showSimilar,
      similarPageIndex,
      similarSortby,
      similarSorting,
      updateProtocol,
      updateChain,
      updateCategory,
      updateSorting,
      updatePageIndex,
      updateCompareProtocol,
      clearCompareProtocol,
      updateShowSimilar,
      updateSimilarSorting,
      updateSimilarPageIndex,
    }}>
      {children}
    </ProtocolContext.Provider>
  );
};