import { SelectOptionType } from "src/types";
import { EstimateProtocolType, EstimateProtocolTypeResponse } from "src/types/protocol.type";

export interface IStateEstimateApr {
  isOpenModal: boolean
  isConfirmModal: boolean
  tokenList:  SelectOptionType[]
  categoryList:  SelectOptionType[]
  estimateProtocolList: EstimateProtocolTypeResponse[]
  protocolList:  SelectOptionType[]
  poolList:  SelectOptionType[]

  tokenSelected: SelectOptionType[]
  categorySelected: SelectOptionType[] | null
  protocolSelected: SelectOptionType[] | null
  poolSelected: SelectOptionType[] | null

  tokenDisabled: boolean
  categoryDisabled: boolean
  protocolDisabled: boolean
  poolDisabled: boolean

  tokenLoading: boolean
  categoryLoading: boolean
  protocolLoading: boolean
  poolLoading: boolean

  estimateReturn: EstimateProtocolTypeResponse[]
};

export const initialState: IStateEstimateApr = {
  isOpenModal: false,
  isConfirmModal: false,
  tokenList: [],
  categoryList: [],
  estimateProtocolList: [],
  protocolList: [],
  poolList: [],

  tokenSelected: [],
  categorySelected: null,
  protocolSelected: null,
  poolSelected: null,

  tokenDisabled: true,
  categoryDisabled: true,
  protocolDisabled: true,
  poolDisabled: true,

  tokenLoading: false,
  categoryLoading: false,
  protocolLoading: false,
  poolLoading: false,

  estimateReturn: JSON.parse(localStorage.getItem("estimateReturn") ?? "[]")
};

type ACTION_TYPE =
  { type: "LISTENER_UPDATE_ESTIMATE_RETURN" } |
  
  { type: "MODAL_ESTIMATION_OPEN" } |
  { type: "MODAL_ESTIMATION_CLOSE" } |
  { type: "MODAL_ESTIMATION_CLEAR" } |

  { type: "MODAL_CONFIRM_OPEN" } |
  { type: "MODAL_CONFIRM_CLOSE" } |

  { type: "INITIAL_ESTIMATE_APR" } |
  { type: "INITIAL_ESTIMATE_APR_SUCCESS", payload: any } |
  { type: "INITIAL_ESTIMATE_APR_FAIL" } |

  { type: "INITIAL_TOKEN_LIST" } |
  { type: "INITIAL_TOKEN_LIST_SUCCESS", payload: SelectOptionType[] } |
  { type: "INITIAL_TOKEN_LIST_FAIL" } |

  { type: "INITIAL_CATEGORY_LIST" } |
  { type: "INITIAL_CATEGORY_LIST_SUCCESS", payload: SelectOptionType[] } |
  { type: "INITIAL_CATEGORY_LIST_FAIL" } |

  { type: "INITIAL_PROTOCOL_LIST" } |
  { type: "INITIAL_PROTOCOL_LIST_SUCCESS", payload: EstimateProtocolType } |
  { type: "INITIAL_PROTOCOL_LIST_FAIL" } |

  { type: "INITIAL_POOL_LIST" } |
  { type: "INITIAL_POOL_LIST_SUCCESS", payload: SelectOptionType[] } |
  { type: "INITIAL_POOL_LIST_FAIL" } |

  { type: "TOKEN_LIST_SELECTED", payload: SelectOptionType[] } |
  { type: "CATEGORY_LIST_SELECTED", payload: SelectOptionType[] | null } |
  { type: "PROTOCOL_LIST_SELECTED", payload: SelectOptionType[] | null } |
  { type: "POOL_LIST_SELECTED", payload: SelectOptionType[] | null } |

  { type: "TOKEN_LIST_SELECTED_CLEAR" } |
  { type: "CATEGORY_LIST_SELECTED_CLEAR" } |
  { type: "PROTOCOL_LIST_SELECTED_CLEAR" } |
  { type: "POOL_LIST_SELECTED_CLEAR" } |

  { type: "ADD_ESTIMATE_RETURN", payload: EstimateProtocolTypeResponse } |
  { type: "REMOVE_ESTIMATE_RETURN", payload: string } |
  { type: "UPDATE_ESTIMATE_RETURN", payload: any } |
  { type: "CLEAR_ESTIMATE_RETURN" } |

  { type: "ESTIMATE_APR_FAIL" } ;

const reducer = (state: IStateEstimateApr, action: ACTION_TYPE) => {
  switch (action.type) {
    case "LISTENER_UPDATE_ESTIMATE_RETURN": {
      const storageEstimateReturn: EstimateProtocolTypeResponse[] = JSON.parse(localStorage.getItem("estimateReturn") ?? "[]")
      return { ...state, estimateReturn: storageEstimateReturn };
    }
    case "MODAL_CONFIRM_OPEN": {
      return { ...state, isConfirmModal: true };
    }
    case "MODAL_CONFIRM_CLOSE": {
      return { ...state, isConfirmModal: false };
    }
    case "MODAL_ESTIMATION_OPEN": {
      return { ...state, isOpenModal: true };
    }
    case "MODAL_ESTIMATION_CLOSE": {
      return { ...state, isOpenModal: false };
    }
    case "MODAL_ESTIMATION_CLEAR": {
      return { 
        ...state, 
        protocolList: [],
        poolList: [],
        categoryDisabled: true,
        protocolDisabled: true,
        poolDisabled: true,
        categoryLoading: false,
        protocolLoading: false,
        poolLoading: false,

        tokenSelected: [],
        categorySelected: [],
        protocolSelected: [],
        poolSelected: []
      };
    }

    case "INITIAL_TOKEN_LIST": {
      return { ...state, tokenLoading: true, tokenDisabled: true };
    }
    case "INITIAL_CATEGORY_LIST": {
      return { ...state, categoryLoading: true, categoryDisabled: true };
    }
    case "INITIAL_PROTOCOL_LIST": {
      return { ...state, protocolLoading: true, protocolDisabled: true };
    }
    case "INITIAL_POOL_LIST": {
      return { ...state, poolLoading: true, poolDisabled: true };
    }

    case "INITIAL_TOKEN_LIST_SUCCESS": {
      const tokenList = action.payload;
      return { ...state, tokenList, tokenLoading: false, tokenDisabled: false };
    }
    case "INITIAL_CATEGORY_LIST_SUCCESS": {
      const categoryList = action.payload;
      return { ...state, categoryList, categoryLoading: false, categoryDisabled: false };
    }
    case "INITIAL_PROTOCOL_LIST_SUCCESS": {
      const { protocolList, estimateProtocolList } = action.payload;
      return { ...state, protocolList, estimateProtocolList, protocolLoading: false, protocolDisabled: false };
    }
    case "INITIAL_POOL_LIST_SUCCESS": {
      const poolList = action.payload;
      return { ...state, poolList, poolLoading: false, poolDisabled: false };
    }

    case "TOKEN_LIST_SELECTED": {
      const tokenSelected = action.payload;
      return { ...state, tokenSelected };
    }
    case "CATEGORY_LIST_SELECTED": {
      const categorySelected = action.payload;
      return { ...state, categorySelected };
    }
    case "PROTOCOL_LIST_SELECTED": {
      const protocolSelected = action.payload;
      return { ...state, protocolSelected };
    }
    case "POOL_LIST_SELECTED": {
      const poolSelected = action.payload;
      return { ...state, poolSelected };
    }

    case "TOKEN_LIST_SELECTED_CLEAR": {
      return { ...state, tokenSelected: [] };
    }
    case "CATEGORY_LIST_SELECTED_CLEAR": {
      return { ...state, categorySelected: [], categoryDisabled: true, categoryLoading: false };
    }
    case "PROTOCOL_LIST_SELECTED_CLEAR": {
      return { ...state, protocolSelected: [], protocolDisabled: true, protocolLoading: false };
    }
    case "POOL_LIST_SELECTED_CLEAR": {
      return { ...state, poolSelected: [], poolDisabled: true, poolLoading: false };
    }

    case "ADD_ESTIMATE_RETURN": {
      const newEstimateReturn = action.payload;
      const updateEstimateReturn = [...state.estimateReturn, newEstimateReturn];
      localStorage.setItem("estimateReturn", JSON.stringify(updateEstimateReturn));
      return { ...state, estimateReturn:  updateEstimateReturn};
    }
    case "REMOVE_ESTIMATE_RETURN": {
      const estimateReturnID = action.payload;
      const estimateReturn = state.estimateReturn.filter(x => x.id !== estimateReturnID);
      localStorage.setItem("estimateReturn", JSON.stringify(estimateReturn));
      return { ...state, estimateReturn };
    }
    case "UPDATE_ESTIMATE_RETURN": {
      const { estimateReturnID, objUpdate } = action.payload;
      const updateEstimateReturn = state.estimateReturn.map((x) => x.id === estimateReturnID? {...x, ...objUpdate}: x);
      localStorage.setItem("estimateReturn", JSON.stringify(updateEstimateReturn));
      return { ...state, estimateReturn: updateEstimateReturn };
    }
    case "CLEAR_ESTIMATE_RETURN": {
      localStorage.setItem("estimateReturn", JSON.stringify([]));
      return { ...state, estimateReturn: [] };
    }

    default:
      return state;
  }
};

const estimateAprPageReducer = {
  reducer,
  initialState
};

export default estimateAprPageReducer;