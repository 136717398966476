
import React, { createContext, useEffect, useReducer } from "react";
import { useNotifier } from "react-headless-notifier";
import estimateAprPageReducer, { initialState, IStateEstimateApr } from "src/containers/estimate/estimateAprPage.reducer";

import { SuccessNotification, WarningNotification } from "src/components/shared/Notification";

import * as servicePool from "src/services/pool.service";
import * as serviceProtocol from "src/services/protocol.service";
import * as serviceCategory from "src/services/category.service";

import { SelectOptionType } from "src/types";
import { EstimateAprParam, EstimateProtocolTypeResponse } from "src/types/protocol.type";

import { showPoolMeta } from "src/utils";
import { MAX_CARD_ESTIMATE_RETURN } from "src/utils/constants";

import { useAnalyticsEventTracker } from "src/utils/useGoogleAnalytics";

interface IEstimateContext extends IStateEstimateApr {
  initialTokenSelect: () => Promise<void>;
  initialCategorySelect: (params: EstimateAprParam, controller?: AbortController) => Promise<void>;
  initialProtocolSelect: (params: EstimateAprParam, controller?: AbortController) => Promise<void>;
  handelOpenModal: () => void;
  handelCloseModal: () => void;
  handelOpenModalConfirm: () => void;
  handelCloseModalConfirm: () => void;
  handelSelectToken: (newToken: SelectOptionType[]) => Promise<void>;
  handelSelectCategory: (newCategory: SelectOptionType) => Promise<void>;
  handelSelectProtocol: (newProtocol: SelectOptionType) => Promise<void>;
  handelSelectPool: (newPool: SelectOptionType) => Promise<void>;
  handelAddEstimateReturn: (newEstimateReturn: EstimateProtocolTypeResponse) => void;
  handelRemoveEstimateReturn: (estimateReturnId: string) => void;
  handelClearEstimateReturn: () => void;
  handelUpdateEstimateReturn: ({ estimateReturnID, objUpdate }: { estimateReturnID: string, objUpdate: any; }) => void;
  checkSlotEstimateReturn: (id: string) => "add" | "delete" | "fully" | "";
};

const defaultValue: IEstimateContext = {
  ...initialState,
  initialTokenSelect: async () => { },
  initialCategorySelect: async () => { },
  initialProtocolSelect: async () => { },
  handelOpenModal: () => { },
  handelCloseModal: () => { },
  handelOpenModalConfirm: () => { },
  handelCloseModalConfirm: () => { },
  handelSelectToken: async () => { },
  handelSelectCategory: async () => { },
  handelSelectProtocol: async () => { },
  handelSelectPool: async () => { },
  handelAddEstimateReturn: () => { },
  handelRemoveEstimateReturn: () => { },
  handelClearEstimateReturn: () => { },
  handelUpdateEstimateReturn: () => { },
  checkSlotEstimateReturn: () => "add"
};

export const EstimateContext = createContext<IEstimateContext>(defaultValue);

export const EstimateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { notify } = useNotifier();
  const { reducer, initialState } = estimateAprPageReducer;
  const [reducerValue, dispatch] = useReducer(reducer, initialState);
  const {
    isOpenModal,
    isConfirmModal,
    tokenList,
    categoryList,
    estimateProtocolList,
    protocolList,
    poolList,
    tokenSelected,
    categorySelected,
    protocolSelected,
    poolSelected,
    tokenDisabled,
    categoryDisabled,
    protocolDisabled,
    poolDisabled,
    tokenLoading,
    categoryLoading,
    protocolLoading,
    poolLoading,
    estimateReturn
  } = reducerValue;
  const eventTracker = useAnalyticsEventTracker();

  const initialTokenSelect = async () => {
    dispatch({ type: "INITIAL_TOKEN_LIST" });
    const resTokenSymbols = await servicePool.initTokens();
    if (resTokenSymbols) {
      dispatch({ type: "INITIAL_TOKEN_LIST_SUCCESS", payload: resTokenSymbols });
    }
  };
  const initialCategorySelect = async (param: EstimateAprParam, controller: AbortController = new AbortController()) => {
    dispatch({ type: "INITIAL_CATEGORY_LIST" });
    const resCategory = await serviceProtocol.getEstimateCategory(param, controller);
    if (resCategory) {
      dispatch({ type: "INITIAL_CATEGORY_LIST_SUCCESS", payload: resCategory });
    }
  };
  const initialProtocolSelect = async (params: EstimateAprParam, controller: AbortController = new AbortController()) => {
    dispatch({ type: "INITIAL_PROTOCOL_LIST" });
    const resEstimateProtocol = await serviceProtocol.getEstimateProtocol(params, controller);
    if (resEstimateProtocol) {
      dispatch({ type: "INITIAL_PROTOCOL_LIST_SUCCESS", payload: resEstimateProtocol });
    }
  };
  const handelOpenModalConfirm = () => {
    dispatch({ type: "MODAL_CONFIRM_OPEN" });
  };
  const handelCloseModalConfirm = () => {
    dispatch({ type: "MODAL_CONFIRM_CLOSE" });
  };
  const handelOpenModal = () => {
    dispatch({ type: "MODAL_ESTIMATION_OPEN" });
  };
  const handelCloseModal = () => {
    dispatch({ type: "MODAL_ESTIMATION_CLOSE" });
    dispatch({ type: "MODAL_ESTIMATION_CLEAR" });
  };
  const handelSelectToken = async (newToken: SelectOptionType[]) => {
    dispatch({ type: "TOKEN_LIST_SELECTED", payload: newToken });
    dispatch({ type: "CATEGORY_LIST_SELECTED_CLEAR" });
    dispatch({ type: "PROTOCOL_LIST_SELECTED_CLEAR" });
    dispatch({ type: "POOL_LIST_SELECTED_CLEAR" });
    if (newToken.length > 0) {
      dispatch({ type: "INITIAL_CATEGORY_LIST" });
    }
  };
  const handelSelectCategory = async (newCategory: SelectOptionType) => {
    dispatch({ type: "CATEGORY_LIST_SELECTED", payload: newCategory ? [newCategory] : null });
    dispatch({ type: "PROTOCOL_LIST_SELECTED_CLEAR" });
    dispatch({ type: "POOL_LIST_SELECTED_CLEAR" });
    if (tokenSelected.length > 0 && newCategory !== null) {
      dispatch({ type: "INITIAL_PROTOCOL_LIST" });
    }
  };
  const handelSelectProtocol = async (newProtocol: SelectOptionType) => {
    dispatch({ type: "PROTOCOL_LIST_SELECTED", payload: newProtocol ? [newProtocol] : null });
    dispatch({ type: "POOL_LIST_SELECTED_CLEAR" });
    if (newProtocol !== null) {
      dispatch({ type: "INITIAL_POOL_LIST" });
      const poolList = estimateProtocolList.filter(x => x.Project.slug === newProtocol?.value);
      const poolListOption = poolList.map((list) => {
        const isDisabled = (estimateReturn.findIndex(x => x.id === list.id) !== -1);
        return {
          value: list.id,
          isDisabled: isDisabled,
          label: (
            <div className="flex flex-row space-x-5 justify-between items-center" data-label={`${list.symbol}${showPoolMeta(list.poolMeta)}${showPoolMeta(list.chain)}<`}>
              <div>{list.symbol}{showPoolMeta(list.poolMeta)}</div>
              <div>{showPoolMeta(list.chain)}</div>
            </div>
          )
        };
      });
      dispatch({ type: "INITIAL_POOL_LIST_SUCCESS", payload: poolListOption });
    }
  };
  const handelSelectPool = async (newPool: SelectOptionType) => {
    dispatch({ type: "POOL_LIST_SELECTED", payload: newPool ? [newPool] : null });
  };
  const checkSlotEstimateReturn = (id: string) => {
    const findEstimateReturn = estimateReturn.find(x => x.id === id);
    return findEstimateReturn ? "delete" : estimateReturn.length >= MAX_CARD_ESTIMATE_RETURN ? "fully" : !findEstimateReturn ? "add" : "";
  };
  const handelRemoveEstimateReturn = (estimateReturnId: string) => {
    dispatch({ type: "REMOVE_ESTIMATE_RETURN", payload: estimateReturnId });
    notify(<SuccessNotification title="Success" message="You succeed in removing the protocol from the ROI estimation page" />);
  };
  const handelAddEstimateReturn = (newEstimateReturn: EstimateProtocolTypeResponse) => {
    if (estimateReturn.length >= MAX_CARD_ESTIMATE_RETURN) {
      return notify(<WarningNotification title="Warning" message="Can't add ROI estimation because slot fully, please try refresh page" />);
    }
    const existEstimateReturn = estimateReturn.find(x => x.id === newEstimateReturn.id);
    if (!existEstimateReturn) {
      dispatch({ type: "ADD_ESTIMATE_RETURN", payload: { ...newEstimateReturn, apr: Number(newEstimateReturn.apr) } });
      notify(<SuccessNotification title="Success" message="You succeed in adding the protocol to the ROI estimation page" />);
      eventTracker("select_item", {
        item_list_id: "add_roi_estimation",
        items: [
          {
            item_id: newEstimateReturn.id, // pool_id
            item_name: newEstimateReturn.symbol, // pool_symbol
            item_variant: newEstimateReturn.poolMeta, // pool_meta
            item_category: newEstimateReturn.Project.name, // protocol
            item_category2: newEstimateReturn.Project.category, // protocol_category
            coupon: Number(newEstimateReturn.apr), // pool_apr
            index: 0, // capital
            price: 0, // estimate_roi
            quantity: 1, // duration (month)
          }
        ]
      });
    }
  };
  const handelClearEstimateReturn = () => {
    dispatch({ type: "CLEAR_ESTIMATE_RETURN" });
    notify(<SuccessNotification title="Success" message="You clear ROI estimate success" />);
  };
  const handelUpdateEstimateReturn = ({ estimateReturnID, objUpdate }: any) => {
    dispatch({ type: "UPDATE_ESTIMATE_RETURN", payload: { estimateReturnID, objUpdate } });
    const existEstimateReturn = estimateReturn.find(x => x.id === estimateReturnID);
    if (existEstimateReturn) {
      const sumEstimateReturn = estimateReturn.reduce((accum, current, index, array) => {
        if (current.id === estimateReturnID) {
          return accum + Number(objUpdate.estimate.replace(/[^0-9.-]+/g, "")) ?? 0;
        } else {
          return accum + Number(current.estimate.replace(/[^0-9.-]+/g, "")) ?? 0;
        }
      }, 0);
      eventTracker("view_item", {
        currency: "USD",
        value: sumEstimateReturn, // total_estimated_roi
        items: [
          {
            item_id: existEstimateReturn.id, // pool_id
            item_name: existEstimateReturn.symbol, // pool_symbol
            item_variant: existEstimateReturn.poolMeta, // pool_meta
            item_category: existEstimateReturn.Project.name, // protocol
            item_category2: existEstimateReturn.Project.category, // protocol_category
            item_list_id: "roi_estimation",
            coupon: existEstimateReturn.apr, // pool_apr
            index: objUpdate.capital, // capital
            price: Number(objUpdate.estimate.replace(/[^0-9.-]+/g, "")), // estimate_roi
            quantity: objUpdate.duration, // duration (month)
          }
        ]
      });
    }
  };

  const onStorageUpdate = (e: StorageEvent) => {
    if (e.key === "estimateReturn") {
      dispatch({ type: "LISTENER_UPDATE_ESTIMATE_RETURN" });
    }
  };
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  return (
    <EstimateContext.Provider value={{
      isOpenModal,
      isConfirmModal,
      tokenList,
      categoryList,
      estimateProtocolList,
      protocolList,
      poolList,
      tokenSelected,
      categorySelected,
      protocolSelected,
      poolSelected,
      tokenDisabled,
      categoryDisabled,
      protocolDisabled,
      poolDisabled,
      tokenLoading,
      categoryLoading,
      protocolLoading,
      poolLoading,
      estimateReturn,
      initialTokenSelect,
      initialCategorySelect,
      initialProtocolSelect,
      handelOpenModalConfirm,
      handelCloseModalConfirm,
      handelOpenModal,
      handelCloseModal,
      handelSelectToken,
      handelSelectCategory,
      handelSelectProtocol,
      handelSelectPool,
      handelRemoveEstimateReturn,
      handelAddEstimateReturn,
      handelClearEstimateReturn,
      checkSlotEstimateReturn,
      handelUpdateEstimateReturn
    }}>
      {children}
    </EstimateContext.Provider>
  );
};