import { GroupBase, StylesConfig } from "react-select";

export const VERSION = process.env.REACT_APP_VERSION;
export const API_ENDPOINT = process.env.REACT_APP_API_SERVER;
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS ?? "";

export const TEXT_UPDATE_DATA_REAL_TIME = "The data always updated in real-time";
export const TEXT_UPDATE_DATA_EVERY_24_HRS = "The data will be updated every 24 hrs";
export const TEXT_CHANGE_APY_APY_7_DAY = "%APY/APR 7d Change";
export const TEXT_DATA_IS_CURRENT_DATE = "The Data belongs to the current date";

export const PAGE_SIZE = 30;
export const MIN_CAPITAL = 0;
export const MAX_CAPITAL = 10000000000000;
export const INPUT_INTEGER_RECJECT_VALUE = ["e", "E", "+", "-", "."];

export const TOKEN_SELECT_LIMIT = 30;

export const MAX_CARD_ESTIMATE_RETURN = 8;

export const SELECT_DEFAULT_STYLE: StylesConfig<any, boolean, GroupBase<any>> = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? WHITE_2 : styles['backgroundColor'],
    cursor: isDisabled ? "not-allowed!important" : styles['cursor'],
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    borderRadius: "0.5rem",
    border: 0,
    boxShadow: "0"
  }),
  valueContainer: styles => ({ ...styles, padding: "0.4375rem 1rem 0.4375rem 1rem" }),
  input: styles => ({ ...styles, marginTop: 3, marginBottom: 3, padding: 0 }),
  menu: styles => ({ ...styles, backgroundColor: BG_SECONDARY, color: WHITE_2, borderRadius: "0.5rem", border: `1px solid ${WHITE_2}`, }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? P600 : (isSelected || isFocused) ? BG_PRIMARY : undefined,
    color: isDisabled ? BG_CARD : (isSelected || isFocused) ? P600 : WHITE_2,
    ':active': { ...styles[':active'], backgroundColor: BG_PRIMARY_ACTIVE },
    'pointer-events': isDisabled ? 'none' : styles[':pointer-events']
  }),
  loadingIndicator: (styles, { isDisabled }) => ({ ...styles, color: isDisabled ? WHITE_1 : styles["color"] }),
  multiValue: styles => ({ ...styles, backgroundColor: BG_SECONDARY }),
  multiValueLabel: styles => ({ ...styles, color: P600, paddingTop: 0, paddingBottom: 0 }),
  multiValueRemove: styles => ({ ...styles, color: DANGER })
};

export const ESTIMATE_SELECT_STYLE: StylesConfig<any, boolean, GroupBase<any>> = {
  menu: styles => ({ ...styles, color: "red", borderRadius: "0.5rem" }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? P600 : (isSelected || isFocused) ? BG_PRIMARY : undefined,
    color: isDisabled ? BG_CARD : (isSelected || isFocused) ? WHITE_1 : BLACK_2,
    ':active': { ...styles[':active'], backgroundColor: BG_PRIMARY_ACTIVE },
    'pointer-events': isDisabled ? 'none' : styles[':pointer-events']
  })
};

export const BG_PRIMARY = "#080F36";
export const BG_PRIMARY_ACTIVE = "#0D1642";
export const BG_SECONDARY = "#242347";
export const BG_CARD = "#C1C2CC";
export const BG_CARD_1 = "#080F36";
export const BG_CARD_2 = "#242347";
export const BG_CARD_SUCCESS = "#C0FFD9";
export const BG_INPUT = "#F5F5F6";

export const WHITE_1 = "#FFFFFF";
export const WHITE_2 = "#C1C2CC";
export const BLACK_1 = "#222222";
export const BLACK_2 = "#3C3C3C";

export const P600 = "#F5F5F6";
export const P500 = "#C1C2CC";
export const SUCCESS = "#00A141";
export const DANGER = "#FF0000";

export const CHART_LINE_1 = "#DBCC40";
export const CHART_LINE_COMPARE_1 = "#9A7DFF";

export const CHART_LINE_2 = "#FFA85C";
export const CHART_LINE_COMPARE_2 = "#2ABCDB";

export const CHART_LINE_3 = "#4ADE28";
export const CHART_LINE_COMPARE_3 = "#DB682A";

export const CHART_LINE_COLOR = [CHART_LINE_3, CHART_LINE_COMPARE_3];
export const CHART_LINE_COLOR_1 = [CHART_LINE_1, CHART_LINE_COMPARE_1];
export const CHART_LINE_COLOR_2 = [CHART_LINE_2, CHART_LINE_COMPARE_2];
export const CHART_LINE_COLOR_3 = [CHART_LINE_3, CHART_LINE_COMPARE_3];

export const EXCEPTION_ENUM = {
  "CHAIN001": "CHAIN001", //Chain not found
  "PROTOCOL001": "PROTOCOL001", //Protocol not found
  "PROTOCOL002": "PROTOCOL002", //Protocol chart not found
  "POOL001": "POOL001", //POOL not found
  "POOL002": "POOL002", //POOL chart not found
  "CATEGORY001": "CATEGORY001", //Category not fond
};