import * as TokensApi from "src/apis/token.api";

export const initTokens = async (controller?: AbortController) => {
  try {
    const { data } = await TokensApi.getTokens({ signal: controller?.signal });
    const dataSelect = data.map((list, key) => {
      return { label: list.symbol, value: list.symbol };
    });
    return dataSelect;
  } catch (error) {
    //! console.error("initTokens", error);
  }
};
