import { AxiosRequestConfig } from "axios";

import {
  IProtocolParam, ProtocolTypeResponse, IProtocolList,
  EstimateAprSearchParam, EstimateProtocolTypeResponse, EstimateAprParam,
  IProtocolDetailData, IProtocolDetailDataResponse, ITvlPool, IProtocolDetail
} from "src/types/protocol.type";
import * as ProtocolApi from "src/apis/protocols.api";
import { getTotalPages, getStartRecord, getEndRecord } from "src/utils/pagination";
import { toPercent, toFormatNumber, formatSelectOption } from "src/utils";
import { PAGE_SIZE } from "src/utils/constants";
import { CHAIN_ICONS } from "src/assets/chainIcons";
import { SelectOptionType } from "src/types";
import { formatSelectProtocolIconOption } from "src/utils/protocol.utils";

const transformProtocolParam = (params: IProtocolParam) => {
  const { protocol, chain, category, pageIndex, sortBy } = params;
  return {
    name: protocol ? protocol.map(({ value }) => value) : [],
    chainName: chain ? chain : [],
    category: category ? category : [],
    skip: pageIndex * PAGE_SIZE,
    take: PAGE_SIZE,
    sorts: sortBy
  };
};

const transformProtocolData = (res: ProtocolTypeResponse, startIndex: number = 0) => {
  const { projects, count } = res;
  const totalPages = getTotalPages(count, PAGE_SIZE);
  const startRecord = getStartRecord(startIndex);
  const endRecord = getEndRecord(startIndex, PAGE_SIZE, count);
  const protocolList: IProtocolList[] = projects.map((protocol, index) => {
    const { id, name, logo, chainName, category, change_7_day, tvl } = protocol;
    const chainIcon = chainName.map(chain => CHAIN_ICONS[chain.toLowerCase() as keyof typeof CHAIN_ICONS]);
    return {
      id,
      no: `${startIndex + (index + 1)}`,
      protocolName: name,
      protocolIcon: logo,
      chainName,
      chainIcon,
      category,
      change: `${toPercent({ value: change_7_day ? change_7_day : 0 })}`,
      tvl: `${toFormatNumber({ value: Number.parseFloat(String(tvl)), isCurrency: true })}`
    };
  });
  return {
    protocolData: protocolList,
    count,
    totalPages,
    startRecord,
    endRecord
  };
};

export const getProtocols = async (param: IProtocolParam, controller?: AbortController) => {
  try {
    const reqParams = transformProtocolParam(param);
    const { skip } = reqParams;
    const { data } = await ProtocolApi.searchProtocols({
      params: reqParams,
      paramsSerializer: { indexes: null },
      signal: controller?.signal
    });
    return transformProtocolData(data, skip);
  } catch (error) {
    //! console.error("initialProtocolPage", error);
  }
};

export const initProtocol = async (controller?: AbortController) => {
  try {
    const { data } = await ProtocolApi.getProtocols({ signal: controller?.signal });
    return data.map(({ id, name }) => ({ label: name, value: id }));
  } catch (error) {
    //! console.error("initProtocol for select", error);
    return [];
  }
};

const transformEstimateProtocolData = (protocolsCategoryList: EstimateProtocolTypeResponse[]): SelectOptionType[] => {
  const estimateProtocolOption: any = new Map();
  protocolsCategoryList.map((list, key) => {
    if (!estimateProtocolOption.has(list.Project.slug)) {
      estimateProtocolOption.set(list.Project.slug, formatSelectProtocolIconOption(list));
    }
  });
  return [...estimateProtocolOption.values()];
};

const transformEstimateAprParam = (params: EstimateAprParam): EstimateAprSearchParam => {
  const { category, tokens } = params;
  return {
    category: category ? category.map(({ value }) => value) : [],
    tokens: tokens ? tokens.map(({ value }) => value).join(",") : [],
  };
};

export const getEstimateCategory = async (param: EstimateAprParam, controller?: AbortController) => {
  try {
    const reqParams = transformEstimateAprParam(param);
    let configs: AxiosRequestConfig = {
      params: reqParams,
      paramsSerializer: { indexes: null }
    };
    if (controller) {
      configs = { ...configs, signal: controller.signal };
    }
    const { data } = await ProtocolApi.getEstimateCategory(configs);
    return formatSelectOption(data);
  } catch (error) {
    //! console.error("getEstimateCategory", error);
  }
};

export const getEstimateProtocol = async (params: EstimateAprParam, controller?: AbortController) => {
  try {
    const reqParams = transformEstimateAprParam(params);
    let configs: AxiosRequestConfig = {
      params: reqParams,
      paramsSerializer: { indexes: null }
    };
    if (controller) {
      configs = { ...configs, signal: controller.signal };
    }
    const { data } = await ProtocolApi.getEstimateProtocol(configs);
    return {
      estimateProtocolList: data,
      protocolList: transformEstimateProtocolData(data)
    };
  } catch (error) {
    //! console.error("getEstimateProtocol", error);
  }
};

const transformProtocolDetailData = (responseData: IProtocolDetailDataResponse): IProtocolDetailData => {
  const { detail, relatedProtocol, topTvlPool } = responseData;
  const newDetail: IProtocolDetail = {
    ...detail,
    chainsIcon: detail.chains.map(chain => CHAIN_ICONS[chain.toLowerCase() as keyof typeof CHAIN_ICONS]),
    tvl: `${toFormatNumber({ value: detail.tvl, isCurrency: true })}`,
    rawTvl: detail.tvl,
    displayActiveUser: detail.activeUser ? `${toFormatNumber({ value: detail.activeUser })}` : "No data", // "Data not available"
  };
  const newTopTvlPool: ITvlPool[] = topTvlPool.map((tokenData) => ({
    id: tokenData.id,
    poolName: `${tokenData.symbol}`,
    reward: tokenData.rewardTokens,
    apr: `${toPercent({ value: tokenData.apr })}`,
    apy: `${toPercent({ value: tokenData.apy })}`,
    pool_tvl: `${toFormatNumber({ value: tokenData.tvlUSD, isCurrency: true })}`,
    poolMeta: tokenData.poolMeta
  }));
  return {
    detail: newDetail,
    relatedProtocol,
    topTvlPool: newTopTvlPool
  };
};

export const getProtocolDetail = async (id: string, controller?: AbortController) => {
  try {
    const { data } = await ProtocolApi.getProtocolDetail(id, { signal: controller?.signal });
    return transformProtocolDetailData(data);
  } catch (error) {
    //! console.error(`getProtocolDetail ${id}`, error);
    throw error;
  }
};

export const getSimilarProtocols = async (param: IProtocolParam, controller?: AbortController) => {
  try {
    const reqParams = transformProtocolParam(param);
    const { skip } = reqParams;
    const { data } = await ProtocolApi.searchSimilarProtocols({
      params: reqParams,
      paramsSerializer: { indexes: null },
      signal: controller?.signal
    });
    return transformProtocolData(data, skip);
  } catch (error) {
    //! console.error(`searchSimilarProtocols`, error);
  }
};