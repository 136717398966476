import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { VERSION } from "src/utils/constants";

// import Layout from 'src/components/layout/Layout';

import { TokenProvider } from "src/context/token.context";
import { ProtocolProvider } from "src/context/protocol.context";
import { EstimateProvider } from "src/context/estimate.context";

const Layout = lazy(() => import("src/components/layout/Layout"));

const AboutPage = lazy(() => import("src/containers/about/AboutPage"));
const RankingPage = lazy(() => import("src/containers/ranking/RankingPage"));
const TokenListPage = lazy(() => import("src/containers/token/TokenListPage"));
const TokenDetailPage = lazy(() => import("src/containers/token/TokenDetailPage"));
const ProtocolListPage = lazy(() => import("src/containers/protocol/ProtocolListPage"));
const ProtocolDetailPage = lazy(() => import("src/containers/protocol/ProtocolDetailPage"));
const EstimateAprPage = lazy(() => import("src/containers/estimate/EstimateAprPage"));
const NotFoundPage = lazy(() => import("src/containers/error/NotFoundPage"));

function App() {
  useEffect(() => {
    console.log(`App Version: ${VERSION}`);
  }, []);

  return (
    <BrowserRouter>
      <EstimateProvider>
        <Suspense fallback={<Layout />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<RankingPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="estimate" element={<EstimateAprPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route element={<TokenProvider><Layout /></TokenProvider>}>
              <Route path="token" element={<TokenListPage />} />
              <Route path="token/detail/:id" element={<TokenDetailPage />} />
            </Route>
            <Route element={<ProtocolProvider><Layout /></ProtocolProvider>}>
              <Route path="protocol" element={<ProtocolListPage />} />
              <Route path="protocol/detail/:id" element={<ProtocolDetailPage />} />
            </Route>
          </Routes>
        </Suspense>
      </EstimateProvider>
    </BrowserRouter>
  );
}

export default App;
