interface IconProps {
  src: string;
  className?: string;
}

const Icon = (props: IconProps) => {
  return (
    <img
      src={props.src}
      alt="icon"
      className={`w-[30px] h-[30px] rounded-full ${props.className ?? ""}`}
    />
  );
};

export default Icon;