export const getTotalPages = (totalRecords: number, pageSize: number) => {
  return Math.ceil(totalRecords / pageSize);
};

export const getStartRecord = (startIndex: number) => {
  return startIndex + 1;
};

export const getEndRecord = (startIndex: number, pageSize: number, totalRecords: number) => {
  const pageIndex = (startIndex / pageSize);
  const endRecord = (pageIndex + 1) * pageSize;
  return Math.min(endRecord, totalRecords);
};