interface NotificationInterface {
  title?: string;
  message?: string;
  dismiss?: () => void;
}

export function SuccessNotification({
  title,
  message,
  dismiss,
}: NotificationInterface): JSX.Element {
  return (
    <div className="w-80 border-l-4 border-green-400 bg-green-50 p-4 text-sm flex items-center shadow-lg relative rounded z-50">
      <button
        type="button"
        className="absolute right-0 top-0 w-4 text-green-400 m-3"
        onClick={dismiss}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {" "}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />{" "}
        </svg>
      </button>
      <div className="w-5 h-5 text-success mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {" "}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />{" "}
        </svg>
      </div>
      <div>
        { title && <p className="text-success text-md font-normal">{title}</p> }
        { message &&  <p className="text-success">{message}</p> }
      </div>
    </div>
  );
}
export function WarningNotification({
  title,
  message,
  dismiss,
}: NotificationInterface): JSX.Element {
  return (
    <div className="w-80 border-l-4 border-yellow-400 bg-yellow-50 p-4 text-sm flex items-center shadow-lg relative rounded z-50">
      <button
        type="button"
        className="absolute right-0 top-0 w-4 text-yellow-400 m-3"
        onClick={dismiss}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {" "}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />{" "}
        </svg>
      </button>
      <div className="w-5 h-5 text-yellow-600 mr-2 flex-shrink-0">
      <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="w-6 h-6" 
          fill="none" 
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
          />
        </svg>
      </div>
      <div>
        { title && <p className="text-yellow-600 text-md font-normal">{title}</p> }
        { message &&  <p className="text-yellow-600">{message}</p> }
      </div>
    </div>
  );
}
