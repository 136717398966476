import axios from "axios";
import { API_ENDPOINT } from "src/utils/constants";

const apiInstance = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json"
  }
});

export {
  apiInstance
};