import { GOOGLE_ANALYTICS } from "src/utils/constants";

interface IUseAnalyticsConsentTracker {
  consentArg: "update" | "default",
  consentParams?: "denied" | "granted";
}

const useAnalyticsInitial = () => {
  const analyticsInitial = () => {
    gtag("js", new Date());
    gtag("config", GOOGLE_ANALYTICS);
  };
  return analyticsInitial;
};

const useAnalyticsEventTracker = () => {
  const eventTracker = (eventName = "page_view", eventParams = {}) => {
    gtag("event", eventName, eventParams);
  };
  return eventTracker;
};

const useAnalyticsConsentTracker = () => {
  const consentTracker = ({ consentArg, consentParams }: IUseAnalyticsConsentTracker) => {
    gtag("consent", consentArg, {
      "ad_storage": "denied",
      "analytics_storage": consentParams
    });
  };
  return consentTracker;
};

export {
  useAnalyticsInitial,
  useAnalyticsConsentTracker,
  useAnalyticsEventTracker
};