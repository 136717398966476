
import React, { useState, createContext, useCallback } from "react";
import { SortingState, OnChangeFn, ColumnSort } from '@tanstack/react-table';
import { TokenListTableType } from "src/types/token.type";
import { SelectOptionType } from "src/types";

export type AprFilterType = "min" | "max";

interface IComparePools {
  id: string;
  name: string;
  tokens: string[];
  meta: string;
  protocol: string;
  protocol_name: string;
  protocol_category: string;
}

export interface ITokenContext {
  pool: SelectOptionType[];
  chain: string[];
  category: string[];
  apyMin: string;
  apyMax: string;
  sorting: SortingState;
  sortBy: string;
  pageIndex: number;
  comparePools: IComparePools[];
  updatePool: (newChain: SelectOptionType[]) => void;
  updateChain: (newChain: string[]) => void;
  updateCategory: (newCategory: string[]) => void;
  updateAPY: (type: AprFilterType, newAPY: string) => void;
  updateSorting: OnChangeFn<SortingState>;
  updatePageIndex: (pageIndex: number) => void;
  updateComparePool: (row: TokenListTableType) => void;
  clearComparePool: () => void;
};

const defaultValue: ITokenContext = {
  pool: [],
  chain: [],
  category: [],
  apyMin: "",
  apyMax: "",
  sorting: [
    { id: "apy", desc: true },
    { id: "tvlUSD", desc: true },
  ],
  sortBy: "tvlUSD:desc,apy:desc",
  pageIndex: 0,
  comparePools: [],
  updatePool: () => { },
  updateChain: () => { },
  updateCategory: () => { },
  updateAPY: () => { },
  updateSorting: () => { },
  updatePageIndex: () => { },
  updateComparePool: () => { },
  clearComparePool: () => { },
};

export const TokenContext = createContext<ITokenContext>(defaultValue);

export const TokenProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [pool, setPool] = useState(defaultValue.pool);
  const [chain, setChain] = useState(defaultValue.chain);
  const [category, setCategory] = useState(defaultValue.category);
  const [apyMin, setAprMin] = useState(defaultValue.apyMin);
  const [apyMax, setAprMax] = useState(defaultValue.apyMin);
  const [sorting, setSorting] = useState(defaultValue.sorting);
  const [sortBy, setSortBy] = useState(defaultValue.sortBy);
  const [pageIndex, setPageIndex] = useState(defaultValue.pageIndex);
  const [comparePools, setComparePools] = useState(defaultValue.comparePools);

  const updatePool = (newPool: SelectOptionType[]) => {
    setPool(newPool);
    setPageIndex(0);
  };
  const updateChain = (newChain: string[]) => {
    setChain(newChain);
    setPageIndex(0);
  };
  const updateCategory = (newCategory: string[]) => {
    setCategory(newCategory);
    setPageIndex(0);
  };
  const updateAPY = (type: AprFilterType, newAPY: string) => {
    if (type === "min") {
      setAprMin(newAPY);
    } else {
      setAprMax(newAPY);
    }
    setPageIndex(0);
  };
  const updateSorting: OnChangeFn<SortingState> = (updaterOrValue) => {
    let tempSort: SortingState;
    if (typeof updaterOrValue === 'function') {
      tempSort = updaterOrValue(sorting);
    } else {
      tempSort = updaterOrValue;
    }
    // wtf why last index in SortingState always return anll?
    // last index always be newer sorting value
    const newValue = tempSort.at(-1);
    let newSort: SortingState = [];
    for (let oldSort of sorting) {
      if (newValue && newValue.id === oldSort.id) {
        newSort.push({ id: oldSort.id, desc: newValue.desc });
      } else {
        newSort.push({ ...oldSort });
      }
    }
    updateSortBy(newValue);
    setSorting(newSort);
  };
  const updateSortBy = (newValue: ColumnSort | any) => {
    const filterdSorting = sorting.filter(({ id }) => id !== newValue.id);
    let newSortBy = `${newValue.id}:${newValue.desc ? "desc" : "asc"}`;
    for (let sorting of filterdSorting) {
      newSortBy += `,${sorting.id}:${sorting.desc ? "desc" : "asc"}`;
    }
    setSortBy(newSortBy);
  };
  const updatePageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };

  const updateComparePool = useCallback((row: TokenListTableType) => {
    // remove selected row by id
    let newComparePools = comparePools.filter((pool) => pool.id !== row.id);
    // if same length mean new add
    if (newComparePools.length === comparePools.length) {
      newComparePools = [...newComparePools, { id: row.id, name: `${row.symbol}`, tokens: row.symbol.split("-"), meta: row.pool_meta ?? "", protocol: row.project.id, protocol_name: row.project.name, protocol_category: row.project.category }];
    }
    setComparePools(newComparePools);
  }, [comparePools]);

  const clearComparePool = () => {
    setComparePools([]);
  };

  return (
    <TokenContext.Provider value={{
      pool,
      chain,
      category,
      apyMin,
      apyMax,
      sortBy,
      sorting,
      pageIndex,
      comparePools,
      updatePool,
      updateChain,
      updateCategory,
      updateAPY,
      updateSorting,
      updatePageIndex,
      updateComparePool,
      clearComparePool,
    }}>
      {children}
    </TokenContext.Provider>
  );
};