import dayjs from "dayjs";
import { DependencyList, useEffect } from "react";

import { ChartType, ITokenDetailResponse } from "src/types/tokenDetail.type";
import { IChart } from "src/types/protocol.type";

interface IToFormatNumber {
  value: number;
  digits?: number;
  isCurrency?: boolean;
  isShort?: boolean;
};

interface IToPercent {
  value?: number | string;
  minDigits?: number;
  maxDigits?: number;
};

interface IFormatTokenChartData {
  chartData: ITokenDetailResponse | null;
  chartDataCompare?: ITokenDetailResponse;
};
export const isDate = (date: string) => !isNaN(Date.parse(date));
export const toNiceCsvDate = (date: string) => isDate(date) ? dayjs(date).format("DD MMM YYYY") : "";
export const toMonthlyDate = (date: string) => isDate(date) ? dayjs(date).format("MMM YYYY") : "";
export const toNiceDateYear = (date: string) => isDate(date) ? dayjs(date).format("MMMM DD, YYYY") : "";

export const shortenAddress = (address: string = ""): string => {
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
};

export const toFormatNumber = ({
  value = 0,
  digits = undefined,
  isCurrency = false,
  isShort = false
}: IToFormatNumber) => {
  let optionConfig: Intl.NumberFormatOptions;
  if (isCurrency) {
    let maximumDigits = digits ?? 0, compactDisplayStyle = {};
    if (value >= 10_000_000 || value <= -10_000_000) {
      maximumDigits = digits ?? 2;
      compactDisplayStyle = { notation: "compact", compactDisplayStyle: "short" };
    }
    optionConfig = { style: "currency", currency: "USD", ...compactDisplayStyle, maximumFractionDigits: maximumDigits };
  } else {
    optionConfig = { style: "decimal", notation: isShort ? "compact" : "standard", compactDisplay: "short", maximumFractionDigits: digits ?? 0 };
  }
  return `${new Intl.NumberFormat("en-US", optionConfig).format(value)}`;
};
export const toPercent = ({ value = 0, minDigits = 2, maxDigits = 2 }: IToPercent) => {
  return `${new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits }).format(Number(value))}%`;
};
export const isNegative = (value: number) => (value < 0 ? true : false);
export const classColorTextPercent = (value: number) => (value > 0) ? "text-green-500" : isNegative(value) ? "text-red-500" : "text-white-2";
export const formatSelectOption = (categoryArray: string[]) => {
  return categoryArray.map((value, key) => {
    return { value: value, label: value };
  });
};

export const formatTokenChartData = ({ chartData, chartDataCompare }: IFormatTokenChartData) => {
  const chartCompare: any = new Map();
  let dataChartDefault: ChartType = { timestamp: "default", apr: null, apy: null, apyBase: null, apyReward: null, tvlUsd: null, activeUser: null };
  const detailHaveActiveUser = chartData?.detail.haveActiveUser;
  if (chartData !== null) {
    for (let data of chartData?.chart) {
      chartCompare.set(data.timestamp, {
        timestamp: data.timestamp,
        apr: Number(data.apr) || null,
        apy: Number(data.apy) || null,
        apyBase: Number(data.apyBase) || null,
        apyReward: Number(data.apyReward) || null,
        tvlUsd: Number(data.tvlUsd) || null,
        activeUser: detailHaveActiveUser ? Number(data.activeUser) : null
      });
    };
  }

  if (chartDataCompare !== undefined) {
    dataChartDefault = { ...dataChartDefault, aprCompare: null, apyCompare: null, apyBaseCompare: null, apyRewardCompare: null, tvlUsdCompare: null, activeUserCompare: null };
    const compareHaveActiveUser = chartDataCompare?.detail.haveActiveUser;
    for (let compareData of chartDataCompare?.chart) {
      if (chartCompare.has(compareData.timestamp)) {
        const dataChart = chartCompare.get(compareData.timestamp);
        chartCompare.set(compareData.timestamp, {
          ...dataChart,
          aprCompare: Number(compareData.apr) || null,
          apyCompare: Number(compareData.apy) || null,
          apyBaseCompare: Number(compareData.apyBase) || null,
          apyRewardCompare: Number(compareData.apyReward) || null,
          tvlUsdCompare: Number(compareData.tvlUsd) || null,
          activeUserCompare: compareHaveActiveUser ? Number(compareData.activeUser) : null
        });
      } else {
        chartCompare.set(compareData.timestamp, {
          ...dataChartDefault,
          timestamp: compareData.timestamp,
          aprCompare: Number(compareData.apr) || null,
          apyCompare: Number(compareData.apy) || null,
          apyBaseCompare: Number(compareData.apyBase) || null,
          apyRewardCompare: Number(compareData.apyReward) || null,
          tvlUsdCompare: Number(compareData.tvlUsd) || null,
          activeUserCompare: compareHaveActiveUser ? Number(compareData.activeUser) : null
        });
      }
    };
  }
  return [...chartCompare.values()].sort((a, b) => dayjs(a.timestamp).diff(dayjs(b.timestamp)));
};

export const showPoolMeta = (poolMeta: string | null | undefined) => `${poolMeta ? ` (${poolMeta})` : ""}`;

export const useAbortController = (fn: (controller: AbortController) => Promise<void>, debounceTime: number = 0, deps: DependencyList) => {
  useEffect(() => {
    let tokenTimeout: NodeJS.Timeout, controller: AbortController;
    controller = new AbortController();
    tokenTimeout = setTimeout(() => {
      fn(controller);
    }, debounceTime);
    return () => {
      clearTimeout(tokenTimeout);
      controller.abort();
    };
  }, deps);
  return null;
};

export const getProtocolChartSet = (chartData: IChart[], haveActiveUser?: boolean) => {
  let chart: any = new Map();
  if (chartData.length) {
    for (let data of chartData) {
      chart.set(data.timestamp, {
        timestamp: data.timestamp,
        tvlUsd: Number(data.tvlUsd) || null,
        activeUser: haveActiveUser ? Number(data.activeUser) : null
      });
    }
  }
  return [...chart.values()].sort((a, b) => dayjs(a.timestamp).diff(dayjs(b.timestamp)));
};

export const getCompareProtocolChartSet = (chartData: IChart[], chartDataCompare: IChart[], haveActiveUser?: boolean) => {
  const defaultChartData: IChart = {
    timestamp: "default",
    tvlUsd: null,
    activeUser: null,
    tvlUsdCompare: null,
    activeUserCompare: null
  };
  let chart: any = new Map(chartData.map(({ timestamp, tvlUsd, activeUser }) => [timestamp, { timestamp, tvlUsd, activeUser }]));
  if (chartDataCompare.length) {
    for (let compareData of chartDataCompare) {
      const { timestamp, tvlUsd, activeUser } = compareData;
      if (chart.has(timestamp)) {
        const oldData = chart.get(timestamp);
        chart.set(timestamp, {
          ...oldData,
          tvlUsdCompare: Number(tvlUsd) || null,
          activeUserCompare: haveActiveUser ? Number(activeUser) : null
        });
      } else {
        chart.set(timestamp, {
          ...defaultChartData,
          timestamp: timestamp,
          tvlUsdCompare: Number(tvlUsd) || null,
          activeUserCompare: haveActiveUser ? Number(activeUser) : null
        });
      }
    }
  }
  return [...chart.values()].sort((a, b) => dayjs(a.timestamp).diff(dayjs(b.timestamp)));
};

export const getTodayActiveUser = (todayData: IChart, isCompare: boolean = false) => {
  const now = dayjs();
  const todayActiveUser = dayjs(todayData.timestamp).isSame(now, "day");
  if (todayActiveUser && isCompare) {
    return todayData.activeUserCompare ? todayData.activeUserCompare : 0;
  }
  if (todayActiveUser) {
    return todayData.activeUser ? todayData.activeUser : 0;
  }
  return 0;
};