import acala from "src/assets/chainImages/acala.png";
import algorand from "src/assets/chainImages/algorand.png";
import aptos from "src/assets/chainImages/aptos.png";
import arbitrumNova from "src/assets/chainImages/arbitrum nova.png";
import arbitrum from "src/assets/chainImages/arbitrum.png";
import astar from "src/assets/chainImages/astar.png";
import aurora from "src/assets/chainImages/aurora.png";
import avalanche from "src/assets/chainImages/avalanche.png";
import bifrost from "src/assets/chainImages/bifrost.png";
import bsc from "src/assets/chainImages/bsc.png";
import bitcoin from "src/assets/chainImages/bitcoin.png";
import bitcoincash from "src/assets/chainImages/bitcoincash.png";
import bitgert from "src/assets/chainImages/bitgert.png";
import bittorrent from "src/assets/chainImages/bittorrent.png";
import boba from "src/assets/chainImages/boba.png";
import bridgekek from "src/assets/chainImages/bridgekek.png";
import bytomsidechain from "src/assets/chainImages/bytomsidechain.png";
import callisto from "src/assets/chainImages/callisto.png";
import candle from "src/assets/chainImages/candle.png";
import canto from "src/assets/chainImages/canto.png";
import carbon from "src/assets/chainImages/carbon.png";
import cardano from "src/assets/chainImages/cardano.png";
import celo from "src/assets/chainImages/celo.png";
import clv from "src/assets/chainImages/clv.png";
import conflux from "src/assets/chainImages/conflux.png";
import cosmos from "src/assets/chainImages/cosmos.png";
import coti from "src/assets/chainImages/coti.png";
import crab from "src/assets/chainImages/crab.png";
import crescent from "src/assets/chainImages/crescent.png";
import cronos from "src/assets/chainImages/cronos.png";
import csc from "src/assets/chainImages/csc.png";
import cube from "src/assets/chainImages/cube.png";
import curio from "src/assets/chainImages/curio.png";
import defichain from "src/assets/chainImages/defichain.png";
import dfk from "src/assets/chainImages/dfk.png";
import doge from "src/assets/chainImages/doge.png";
import dogechain from "src/assets/chainImages/dogechain.png";
import echelon from "src/assets/chainImages/echelon.png";
import elastos from "src/assets/chainImages/elastos.png";
import elrond from "src/assets/chainImages/elrond.png";
import energi from "src/assets/chainImages/energi.png";
import energyweb from "src/assets/chainImages/energyweb.png";
import eos from "src/assets/chainImages/eos.png";
import ergo from "src/assets/chainImages/ergo.png";
import ethereum from "src/assets/chainImages/ethereum.png";
import ethereumclassic from "src/assets/chainImages/ethereumclassic.png";
import ethereumpow from "src/assets/chainImages/ethereumpow.png";
import everscale from "src/assets/chainImages/everscale.png";
import evmos from "src/assets/chainImages/evmos.png";
import fantom from "src/assets/chainImages/fantom.png";
import filecoin from "src/assets/chainImages/filecoin.png";
import findora from "src/assets/chainImages/findora.png";
import flow from "src/assets/chainImages/flow.png";
import functionx from "src/assets/chainImages/functionx.png";
import fuse from "src/assets/chainImages/fuse.png";
import fusion from "src/assets/chainImages/fusion.png";
import genshiro from "src/assets/chainImages/genshiro.png";
import gnosis from "src/assets/chainImages/gnosis.png";
import gochain from "src/assets/chainImages/gochain.png";
import godwoken from "src/assets/chainImages/godwoken.png";
import godwokenv1 from "src/assets/chainImages/godwokenv1.png";
import harmony from "src/assets/chainImages/harmony.png";
import heco from "src/assets/chainImages/heco.png";
import hedera from "src/assets/chainImages/hedera.png";
import heiko from "src/assets/chainImages/heiko.png";
import hoo from "src/assets/chainImages/hoo.png";
import hpb from "src/assets/chainImages/hpb.png";
import hydra from "src/assets/chainImages/hydra.png";
import icon from "src/assets/chainImages/icon.png";
import icp from "src/assets/chainImages/icp.png";
import interlay from "src/assets/chainImages/interlay.png";
import iotex from "src/assets/chainImages/iotex.png";
import juno from "src/assets/chainImages/juno.png";
import kadena from "src/assets/chainImages/kadena.png";
import kardia from "src/assets/chainImages/kardia.png";
import karura from "src/assets/chainImages/karura.png";
import kava from "src/assets/chainImages/kava.png";
import kcc from "src/assets/chainImages/kcc.png";
import kekchain from "src/assets/chainImages/kekchain.png";
import kintsugi from "src/assets/chainImages/kintsugi.png";
import klaytn from "src/assets/chainImages/klaytn.png";
import kucoin from "src/assets/chainImages/kucoin.png";
import kujira from "src/assets/chainImages/kujira.png";
import kusama from "src/assets/chainImages/kusama.png";
import lachain from "src/assets/chainImages/lachain.png";
import lamden from "src/assets/chainImages/lamden.png";
import lbry from "src/assets/chainImages/lbry.png";
import liquidchain from "src/assets/chainImages/liquidchain.png";
import litecoin from "src/assets/chainImages/litecoin.png";
import meter from "src/assets/chainImages/meter.png";
import metis from "src/assets/chainImages/metis.png";
import milkomedaC1 from "src/assets/chainImages/milkomeda c1.png";
import mixin from "src/assets/chainImages/mixin.png";
import moonbeam from "src/assets/chainImages/moonbeam.png";
import moonriver from "src/assets/chainImages/moonriver.png";
import multivac from "src/assets/chainImages/multivac.png";
import nahmii from "src/assets/chainImages/nahmii.png";
import near from "src/assets/chainImages/near.png";
import neo from "src/assets/chainImages/neo.png";
import novaNetwork from "src/assets/chainImages/nova network.png";
import nuls from "src/assets/chainImages/nuls.png";
import oasis from "src/assets/chainImages/oasis.png";
import obyte from "src/assets/chainImages/obyte.png";
import okexchain from "src/assets/chainImages/okexchain.png";
import ontology from "src/assets/chainImages/ontology.png";
import ontologyevm from "src/assets/chainImages/ontologyevm.png";
import optimism from "src/assets/chainImages/optimism.png";
import ore from "src/assets/chainImages/ore.png";
import osmosis from "src/assets/chainImages/osmosis.png";
import pallete from "src/assets/chainImages/pallete.png";
import palm from "src/assets/chainImages/palm.png";
import parallel from "src/assets/chainImages/parallel.png";
import polis from "src/assets/chainImages/polis.png";
import polkadot from "src/assets/chainImages/polkadot.png";
import polygon from "src/assets/chainImages/polygon.png";
import polynetwork from "src/assets/chainImages/polynetwork.png";
import posichain from "src/assets/chainImages/posichain.png";
import proton from "src/assets/chainImages/proton.png";
import ravencoin from "src/assets/chainImages/ravencoin.png";
import reef from "src/assets/chainImages/reef.png";
import rei from "src/assets/chainImages/rei.png";
import reichain from "src/assets/chainImages/reichain.png";
import ripple from "src/assets/chainImages/ripple.png";
import ronin from "src/assets/chainImages/ronin.png";
import rsk from "src/assets/chainImages/rsk.png";
import secret from "src/assets/chainImages/secret.png";
import shiden from "src/assets/chainImages/shiden.png";
import sifchain from "src/assets/chainImages/sifchain.png";
import skale from "src/assets/chainImages/skale.png";
import smartbch from "src/assets/chainImages/smartbch.png";
import solana from "src/assets/chainImages/solana.png";
import songbird from "src/assets/chainImages/songbird.png";
import sora from "src/assets/chainImages/sora.png";
import stacks from "src/assets/chainImages/stacks.png";
import stafi from "src/assets/chainImages/stafi.png";
import starcoin from "src/assets/chainImages/starcoin.png";
import stellar from "src/assets/chainImages/stellar.png";
import sxnetwork from "src/assets/chainImages/sxnetwork.png";
import syscoin from "src/assets/chainImages/syscoin.png";
import telos from "src/assets/chainImages/telos.png";
import terra from "src/assets/chainImages/terra.png";
import terra2 from "src/assets/chainImages/terra2.png";
import tezos from "src/assets/chainImages/tezos.png";
import theta from "src/assets/chainImages/theta.png";
import thorchain from "src/assets/chainImages/thorchain.png";
import thundercore from "src/assets/chainImages/thundercore.png";
import tombchain from "src/assets/chainImages/tombchain.png";
import tomochain from "src/assets/chainImages/tomochain.png";
import tron from "src/assets/chainImages/tron.png";
import ubiq from "src/assets/chainImages/ubiq.png";
import ultra from "src/assets/chainImages/ultra.png";
import ultron from "src/assets/chainImages/ultron.png";
import vechain from "src/assets/chainImages/vechain.png";
import velas from "src/assets/chainImages/velas.png";
import vision from "src/assets/chainImages/vision.png";
import vite from "src/assets/chainImages/vite.png";
import wanchain from "src/assets/chainImages/wanchain.png";
import waves from "src/assets/chainImages/waves.png";
import wax from "src/assets/chainImages/wax.png";
import xdai from "src/assets/chainImages/xdai.png";
import xdc from "src/assets/chainImages/xdc.png";
import zilliqa from "src/assets/chainImages/zilliqa.png";
import zksync from "src/assets/chainImages/zksync.png";
import zyx from "src/assets/chainImages/zyx.png";

export const CHAIN_ICONS = {
  "acala": acala,
  "algorand": algorand,
  "aptos": aptos,
  "arbitrum nova": arbitrumNova,
  "arbitrum": arbitrum,
  "astar": astar,
  "aurora": aurora,
  "avalanche": avalanche,
  "bifrost": bifrost,
  "bsc": bsc,
  "bitcoin": bitcoin,
  "bitcoincash": bitcoincash,
  "bitgert": bitgert,
  "bittorrent": bittorrent,
  "boba": boba,
  "bridgekek": bridgekek,
  "bytomsidechain": bytomsidechain,
  "callisto": callisto,
  "candle": candle,
  "canto": canto,
  "carbon": carbon,
  "cardano": cardano,
  "celo": celo,
  "clv": clv,
  "conflux": conflux,
  "cosmos": cosmos,
  "coti": coti,
  "crab": crab,
  "crescent": crescent,
  "cronos": cronos,
  "csc": csc,
  "cube": cube,
  "curio": curio,
  "defichain": defichain,
  "dfk": dfk,
  "doge": doge,
  "dogechain": dogechain,
  "echelon": echelon,
  "elastos": elastos,
  "elrond": elrond,
  "energi": energi,
  "energyweb": energyweb,
  "eos": eos,
  "ergo": ergo,
  "ethereum": ethereum,
  "ethereumclassic": ethereumclassic,
  "ethereumpow": ethereumpow,
  "everscale": everscale,
  "evmos": evmos,
  "fantom": fantom,
  "filecoin": filecoin,
  "findora": findora,
  "flow": flow,
  "functionx": functionx,
  "fuse": fuse,
  "fusion": fusion,
  "genshiro": genshiro,
  "gnosis": gnosis,
  "gochain": gochain,
  "godwoken": godwoken,
  "godwokenv1": godwokenv1,
  "harmony": harmony,
  "heco": heco,
  "hedera": hedera,
  "heiko": heiko,
  "hoo": hoo,
  "hpb": hpb,
  "hydra": hydra,
  "icon": icon,
  "icp": icp,
  "interlay": interlay,
  "iotex": iotex,
  "juno": juno,
  "kadena": kadena,
  "kardia": kardia,
  "karura": karura,
  "kava": kava,
  "kcc": kcc,
  "kekchain": kekchain,
  "kintsugi": kintsugi,
  "klaytn": klaytn,
  "kucoin": kucoin,
  "kujira": kujira,
  "kusama": kusama,
  "lachain": lachain,
  "lamden": lamden,
  "lbry": lbry,
  "liquidchain": liquidchain,
  "litecoin": litecoin,
  "meter": meter,
  "metis": metis,
  "milkomeda c1": milkomedaC1,
  "mixin": mixin,
  "moonbeam": moonbeam,
  "moonriver": moonriver,
  "multivac": multivac,
  "nahmii": nahmii,
  "near": near,
  "neo": neo,
  "nova network": novaNetwork,
  "nuls": nuls,
  "oasis": oasis,
  "obyte": obyte,
  "okexchain": okexchain,
  "ontology": ontology,
  "ontologyevm": ontologyevm,
  "optimism": optimism,
  "ore": ore,
  "osmosis": osmosis,
  "pallete": pallete,
  "palm": palm,
  "parallel": parallel,
  "polis": polis,
  "polkadot": polkadot,
  "polygon": polygon,
  "polynetwork": polynetwork,
  "posichain": posichain,
  "proton": proton,
  "ravencoin": ravencoin,
  "reef": reef,
  "rei": rei,
  "reichain": reichain,
  "ripple": ripple,
  "ronin": ronin,
  "rsk": rsk,
  "secret": secret,
  "shiden": shiden,
  "sifchain": sifchain,
  "skale": skale,
  "smartbch": smartbch,
  "solana": solana,
  "songbird": songbird,
  "sora": sora,
  "stacks": stacks,
  "stafi": stafi,
  "starcoin": starcoin,
  "stellar": stellar,
  "sxnetwork": sxnetwork,
  "syscoin": syscoin,
  "telos": telos,
  "terra": terra,
  "terra2": terra2,
  "tezos": tezos,
  "theta": theta,
  "thorchain": thorchain,
  "thundercore": thundercore,
  "tombchain": tombchain,
  "tomochain": tomochain,
  "tron": tron,
  "ubiq": ubiq,
  "ultra": ultra,
  "ultron": ultron,
  "vechain": vechain,
  "velas": velas,
  "vision": vision,
  "vite": vite,
  "wanchain": wanchain,
  "waves": waves,
  "wax": wax,
  "xdai": xdai,
  "xdc": xdc,
  "zilliqa": zilliqa,
  "zksync": zksync,
  "zyx": zyx,
};